<template>
  <header class="app-header navbar">
    <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" @click="mobileSidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <b-link class="navbar-brand" to="/orgs"></b-link>
    <button class="navbar-toggler sidebar-toggler d-lg-down-none" type="button" @click="sidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <breadcrumb/>
    <div class="ml-auto" />
    <notification-bell v-if="dashboardNotificationsEnabled" />
    <help-header-dropdown />
    <user-header-dropdown/>
  </header>
</template>
<script>
import UserHeaderDropdown from './UserHeaderDropdown.vue'
import Breadcrumb from './Breadcrumb.vue'
import HelpHeaderDropdown from '../components/HelpHeaderDropdown.vue'
import NotificationBell from '../components/NotificationBell.vue'
import { mapState } from 'vuex'

export default {
  name: 'c-header',
  inheritAttrs: false,
  components: {
    Breadcrumb,
    UserHeaderDropdown,
    HelpHeaderDropdown,
    NotificationBell
  },
  computed: {
    ...mapState({
      dashboardNotificationsEnabled: state => state.dashboardNotifications.running
    })
  },
  methods: {
    sidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-hidden')
    },
    mobileSidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-mobile-show')
    }
  }
}
</script>
<style scoped>
.app-header {
  justify-content: flex-start;
  flex-wrap: nowrap;
  z-index: 1102 !important; /* higher than p-sidebar's z-index of 1101 */
}

/* Change default core-ui behavior such that when below lg width,
   the brand will be minimized even if navbar is not.*/
@media (max-width: 1200px) {
  .app-header.navbar {
    :deep(.navbar-brand) {
      min-width: 24px;
      width: var(--navbar-brand-minimized-width);
      margin-left: -3px;
      margin-right: 4px;
      background-color: var(--navbar-brand-minimized-bg);
      background-image: var(--navbar-brand-minimized-logo);
      background-size: var(--navbar-brand-minimized-logo-size);
    }
  }
}

.navbar-toggler {
  border: none;
  box-shadow: none;
}

.d-lg-none {
  @apply inline-block lg:hidden;
}

.d-lg-down-none {
  @apply hidden lg:inline-block;
}

</style>
