import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'

registerListResourceWithStore('integrationConnections', 'integration/connections', store)

registerListResourceWithStore('integrationTemplates', 'integration/templates', store)

export default {
  name: 'integrations',
  label: 'Integrations',
  link: 'integrations',
  path: 'integrations',
  props: true,
  icon: 'plug',
  description: 'View and edit your third party integrations.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  requireFeature: 'integration',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'integrations-apikeys',
      label: 'API Keys',
      path: 'apiKeys',
      requireFeature: 'api',
      component: () => import('./apikeys/ApiKeyList.vue')
    },
    {
      name: 'integrations-connections',
      label: 'Connections',
      path: 'connections',
      component: () => import('./connections/Connections.vue')
    },
    {
      name: 'integrations-template-list',
      label: 'Templates',
      path: 'templates/:view?',
      props: true,
      component: () => import('./templates/TemplateList.vue')
    },
    {
      name: 'integrations-index',
      path: '',
      redirect: { name: 'integrations-apikeys' }
    }
  ]
}
