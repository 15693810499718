import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import payrollClient from '@/services/clients/payroll'
import store from '@/store'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'

registerSingletonResourceWithStore('timeOffDefaults', 'timeoff/defaults', store, {
  getters: {
    // The following getters are used in Manage => Time Off views.
    // We didn't put them in the root vuex state, because they're only used within those views.
    daysOfWeek: state => _.get(state.originalData, 'daysOfWeek', []),
    fullDayDuration: state => _.get(state.originalData, 'fullDayDuration', 8*60)
  }
})
registerListResourceWithStore('timeOffCodes', 'timeoff/codes', store)
registerListResourceWithStore('timeOffEntitlements', 'timeoff/entitlements', store, { restClient: payrollClient })
registerListResourceWithStore('timeOffPolicies', 'timeoff/policies', store, { restClient: payrollClient })

setTimeout(() => {
  // Sync changes from time off defaults to root store module.
  store.watch(
    state => ({
      timeOffEnabled: state.timeOffDefaults.originalData.enabled,
      timeOffAccrualEnabled: state.timeOffDefaults.originalData.trackAccruals
    }),
    syncSettings => {
      if (syncSettings.timeOffEnabled === undefined) {
        // This is a litmus test to determine whether Vue is just loading,
        // or whether something actually changed.
        return
      }
      // Get same keys in root state
      const rootSettings = _.pick(store.state, Object.keys(syncSettings))
      const updated = updatedDiff(rootSettings, syncSettings)
      if (!_.isEmpty(updated)) {
        store.dispatch('updateSession', updated)
      }
    }
  )
})

export default {
  name: 'timeoff',
  label: 'Time Off',
  link: 'timeoff',
  path: 'timeoff',
  props: true,
  icon: 'umbrella-beach',
  description: 'View and edit your time off settings.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  requireFeature: 'pto',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'timeoff-defaults',
      label: 'Defaults',
      title: 'Time Off Defaults',
      path: 'defaults',
      component: () => import('./TimeOffDefaults.vue')
    },
    {
      name: 'timeoff-codes',
      label: 'Time Off Codes',
      path: 'codes',
      requireGetter: 'timeOffEnabled',
      component: () => import('./TimeOffCodeList.vue')
    },
    {
      name: 'timeoff-holidays',
      label: 'Holidays',
      path: 'holidays',
      requireGetter: 'timeOffEnabled',
      component: () => import('./holidays/Holidays.vue')
    },
    {
      name: 'timeoff-policies',
      label: 'Policies',
      title: 'Time Off Policies',
      path: 'policies',
      requireGetter: 'timeOffAccrualEnabled',
      component: () => import('./TimeOffPolicies.vue')
    },
    {
      name: 'timeoff-index',
      path: '',
      redirect: { name: 'timeoff-defaults' }
    }
  ]
}
