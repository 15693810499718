export default {
  name: 'manage-timecards',
  label: 'Time Cards',
  path: 'timecards',
  link: 'timecards',
  props: true,
  icon: 'table',
  description: 'View and edit worker time cards.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_punches',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'timecard-editor',
      label: 'Edit',
      title: 'Edit Timecards',
      path: 'edit/:view?',
      component: () => import('./TimeCards.vue')
    },
    {
      name: 'legacy-timecard-editor',
      label: 'Single Edit',
      title: 'Edit Timecards',
      path: 'legacy-edit/:view?',
      component: () => import('@/views/LegacyContainer.vue'),
      props: {
        legacyClassName: 'Fc.timecard.view.Index'
      },
      nav: false
    },
    {
      name: 'shiftcard-list',
      label: 'Bulk Edit',
      title: 'Bulk Edit Timecards',
      path: 'bulk-edit/:view?',
      requireGetter: 'scheduleEnabled',
      component: () => import('./ShiftCardList.vue')
    },
    {
      name: 'manage-timecards-index',
      path: '',
      redirect: { name: 'timecard-editor' }
    }
  ]
}
