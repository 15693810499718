<template>
  <panel title="Roll Call Summary" v-mask="maskOptions">
    <template #icons>
      <panel-header-icon icon="sync" @click="refresh" />
    </template>
    <div>
      <div class="item">
        <font-awesome-icon icon="bullhorn" />
        <Button v-if="workersPresentCount > 0" class="description" label="Workers present:" variant="link" @click="toggleWorkersPresent" />
        <div v-else class="description">Workers present:</div>
        <div class="value">{{ workersPresentCount }}</div>
      </div>
      <div v-if="costingEnabled" class="item">
        <font-awesome-icon icon="sitemap" />
        <Button v-if="jobsPresentCount > 0" class="description" label="Jobs present:" variant="link" @click="toggleJobsPresent" />
        <div v-else class="description">Jobs present:</div>
        <div class="value">{{ jobsPresentCount }}</div>
      </div>
    </div>
    <router-link :to="reportLink" class="mt-2 ml-1 inline-block">View full Roll Call report</router-link>
    <PVPopover ref="workerPop">
      <div class="max-h-96 overflow-y-auto">
        <div v-for="worker in workersPresent">
          {{ worker }}
        </div>
      </div>
    </PVPopover>
    <PVPopover ref="jobPop">
      <Tree :value="jobTreeNodes" class="w-full p-0 max-h-96 overflow-y-auto" :pt="{
        nodeContent: { class: 'p-0 gap-0 group' },
        nodeToggleButton: { class: ['ml-0', 'group-[.worker]:h-0'] }
      }">
        <template #job="slotProps">
          <router-link :to="getReportLinkForJob(slotProps.node.key)" class="inline-block">{{ slotProps.node.label }}</router-link>
        </template>
      </Tree>
      <router-link :to="jobReportLink" class="mt-2 ml-1 inline-block">View Roll Call report by Job</router-link>
    </PVPopover>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from 'primevue/button'
import Popover from 'primevue/popover'
import Tree from 'primevue/tree'
import _ from 'lodash'
import AutoRefresh from '@/mixins/AutoRefresh'
import Maskable from '@/mixins/Maskable'
import Panel from '@/components/Panel.vue'
import PanelHeaderIcon from '@/components/PanelHeaderIcon.vue'
import service from '@/modules/reports/views/rollcall/RollCallService'

export default {
  mixins: [AutoRefresh, Maskable],
  components: {
    Button,
    Panel,
    PanelHeaderIcon,
    PVPopover: Popover,
    Tree
  },
  data () {
    return {
      results: [],
      supplementalData: {},
      reportLink: {
        name: 'roll-call',
        params: {
          view: JSON.stringify({
            status: 'present'
          })
        }
      },
      jobReportLink: {
        name: 'roll-call',
        params: {
          view: JSON.stringify({
            status: 'present',
            group: ['jobName']
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['costingEnabled']),
    ...mapGetters('formatPreferences', ['formatName']),
    presentResults () {
      return this.results.filter(r => r.present)
    },
    workersPresentCount () {
      return this.presentResults.length
    },
    jobsPresent () {
      return new Set(this.presentResults.flatMap(r => r.job ? [r.job] : []))
    },
    jobsPresentCount () {
      return this.jobsPresent.size
    },
    workersPresent () {
      return this.presentResults.map(r => this.formatName(r.firstName, r.lastName, r.middleName, r.displayName)).sort()
    },
    jobTreeNodes () {
      const presentByJob = Object.entries(_.groupBy(this.presentResults, 'job')).flatMap(([job, present]) => {
        const jobName = this.supplementalData.job?.[job]?.name ?? ''
        if (!jobName) return [] // no job would have had "null" job key
        return [{
          key: job,
          label: `${jobName} (${present.length})`,
          type: 'job',
          children: _.orderBy(present.map(p => ( { key: p.employee, label: this.formatName(p.firstName, p.lastName, p.middleName, p.displayName), styleClass: 'worker' })), 'label')
        }]
      })
      return _.orderBy(presentByJob, 'label')
    }
  },
  methods: {
    load () {
      this.showLoadingMask()
      return service.list({ status: 'present' })
        .then(data => {
          this.results = data.results
          this.supplementalData = data.supplementalData
          this.hideMask()
        })
        .catch(error => {
          this.showErrorMask(error)
          return Promise.reject(error)
        })
    },
    refreshImpl () {
      return this.load()
    },
    refresh () {
      this.coordinateWithRefresh(() => this.load())
    },
    toggleWorkersPresent (event) {
      this.$refs.workerPop.toggle(event)
    },
    toggleJobsPresent (event) {
      this.$refs.jobPop.toggle(event)
    },
    getReportLinkForJob (jobId) {
      return {
        name: 'roll-call',
        params: {
          view: JSON.stringify({
            status: 'present',
            job: [parseInt(jobId)]
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;

    svg {
      width: 25px;
      margin-right: .5rem;
    }
    .description {
      width: 7.5rem;
      white-space: nowrap;
      margin-right: .5rem;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      @apply text-sm;
    }
    .value {
      width: 2.5rem;
      text-align: right;
      font-weight: bold;
    }
  }
</style>
