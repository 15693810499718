<template>
  <b-alert v-if="isVisible" :model-value="true" class="subscription-alert-bar text-center" :variant="variant" :dismissible="closeable" @dismissed="close">
    <font-awesome-icon v-if="isWarningOrAbove" icon="triangle-exclamation" />
    <span class="subscription-alert-bar-text">{{ textWithDate }}</span>
    <br class="md:hidden">
    <b-button v-if="showButton" :to="action" :variant="variant" size="sm">{{ anchorText }}</b-button>
  </b-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

const modeConfig = {
  inApp: {
    variant: 'warning',
    text: 'Register for a Free Trial for full web site access',
    anchorText: 'Register now',
    action: 'sign-up'
  },
  trialEnding: {
    variant: 'warning',
    text: 'Your free trial period is ending soon. It will expire on {{DATE}}.',
    anchorText: 'Upgrade now',
    action: 'settings/billing/subscription'
  },
  freeTrial: {
    variant: 'primary',
    text: 'You are currently using the free trial. It will expire on {{DATE}}.',
    anchorText: 'Upgrade now',
    action: 'settings/billing/subscription'
  },
  trialExpired: {
    variant: 'danger',
    text: 'Your free trial period has expired.',
    anchorText: 'Upgrade now',
    action: 'settings/billing/subscription'
  },
  expired: {
    variant: 'danger',
    text: 'Your subscription has expired.',
    anchorText: 'Reactivate',
    action: 'settings/billing/subscription'
  },
  pastDue: {
    variant: 'danger',
    text: 'Your account is Past Due. The credit card on file is no longer active.',
    anchorText: 'Update credit card',
    action: 'settings/billing/subscription'
  }
}

export default {
  data () {
    return {
      userClosed: false
    }
  },
  computed: {
    ...mapState([
      'organizationId',
      'settingsLoaded',
      'subscriptionState',
      'originalSubscriptionState',
      'expireDate',
      'organizationActive',
      'permissions',
      'fatalError',
      'route'
    ]),
    ...mapGetters(['insideOrgNamespace', 'isFreeTrial', 'needsSetup']),
    ...mapGetters('userProfile', ['isWhiteLabel']),
    isVisible () {
      return !this.userClosed && this.settingsLoaded && this.insideOrgNamespace && !!this.mode && !this.fatalError
    },
    showButton () {
      // 1. Although we show all users the subscription bar, only users with billing permission can act on it.
      // 2. White label user billing is external, so don't show action. We do show the bar though,
      //    because perhaps for now it may be useful for free trials.
      // 3. Hide button when app is already on page that button links to.
      return this.permissions.includes('manage_subscription') && !this.isWhiteLabel && this.route.path !== this.action
    },
    mode () {
      if (this.inApp) return 'inApp'
      if (this.trialEnding) return 'trialEnding'
      if (this.trialExpired) return 'trialExpired'
      // Don't show free trial banner when we're in the middle of converting from in app to paid plan.
      if (this.isFreeTrial && this.originalSubscriptionState !== 'in_app') return 'freeTrial'
      if (this.expired) return 'expired'
      if (this.pastDue) return 'pastDue'
      return null
    },
    inApp () {
      return this.needsSetup
    },
    trialEnding () {
      return this.isFreeTrial && !this.expired && this.expiresOn.diff(moment(), 'days') < 7
    },
    trialExpired () {
      return this.isFreeTrial && this.expired
    },
    expired () {
      return this.organizationActive === false
    },
    pastDue () {
      return this.subscriptionState === 'past_due'
    },
    expiresOn () {
      return this.expireDate ? moment(this.expireDate) : null
    },
    isWarningOrAbove () {
      return ['warning', 'danger'].includes(this.variant)
    },
    variant () {
      return _.get(modeConfig, [this.mode, 'variant'])
    },
    text () {
      return _.get(modeConfig, [this.mode, 'text'])
    },
    textWithDate () {
      const text = this.text
      if (!text || !this.expireDate) return text
      return text.replace('{{DATE}}', this.expiresOn.format('ll'))
    },
    action () {
      const config = modeConfig[this.mode]
      if (!config) return null
      return `/orgs/${this.organizationId}/${config.action}`
    },
    anchorText () {
      return _.get(modeConfig, [this.mode, 'anchorText'])
    },
    closeable () {
      return !this.isWarningOrAbove
    }
  },
  watch: {
    isVisible: {
      handler (isVisible) {
        document.body.classList[isVisible ? 'add' : 'remove']('alert-bar-visible')
      },
      immediate: true
    }
  },
  methods: {
    close () {
      this.userClosed = true
    }
  }
}
</script>
<style scoped>
.subscription-alert-bar {
  position: fixed;
  z-index: 2;
  width: 100%;
  padding-top: 17px;

  font-weight: bold;

  height: var(--alert-bar-xs-height);
  @media screen(sm) {
    height: var(--alert-bar-sm-height);
  }
  @media screen(md) {
    height: var(--alert-bar-height);
  }

  svg {
    margin-right: .5rem;
  }

  .btn {
    margin-left: 15px;
  }
}

</style>
