<template>
    <b-modal
      v-bind="$attrs"
      ref="modal"
      centered
      no-fade
      modal-class="feedback-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :no-header-close="true"
      :ok-disabled="submitting || (!submitted && !hasFeedback)"
      :ok-only="submitted"
      :ok-title="submitted ? 'Close' : 'Submit'"
      :cancel-disabled="submitting"
      title="Submit Feedback"
      @ok="ok"
    >
    <form-group label="Click to Rate">
      <star-rating
        v-model:rating="rating"
        :show-rating="false"
        :star-size="40"
        :read-only="submitting || submitted"
      />
    </form-group>
    <form-group label="Comments">
      <b-form-textarea
        v-model="comments"
        :rows="5"
        no-resize
        :disabled="submitting || submitted"
      ></b-form-textarea>
    </form-group>
    <div v-if="submitting" key="submitting" class="submitting">
      <font-awesome-icon icon="circle-notch" spin />
      <span>Submitting...</span>
    </div>
    <div v-if="errorMessage" class="error-message" key="error-message">
      <font-awesome-icon icon="triangle-exclamation" />
      <span>{{ errorMessage }}</span>
    </div>
    <div v-if="submitted" class="submitted" key="submitted">
      <font-awesome-icon icon="circle-check" />
      <span>Thanks for the feedback!</span>
    </div>
  </b-modal>
</template>
<script>
import service from '@/modules/dashboard/services/FeedbackService'
import { extractErrorMessage} from '@/utils/misc'
import StarRating from 'vue-star-rating'

export default {
  name: 'FeedbackModal',
  inheritAttrs: false,
  components: {
    StarRating
  },
  data () {
    return {
      submitting: false,
      submitted: false,
      errorMessage: null,
      rating: null,
      comments: null
    }
  },
  computed: {
    hasFeedback () {
      return !!this.rating || this.comments
    }
  },
  methods: {
    ok (bvModalEvent) {
      if (this.submitted) return
      bvModalEvent.preventDefault()
      this.submitting = true
      this.errorMessage = null
      service.submitFeedback(this.rating, this.comments)
        .then(() => { this.submitted = true })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.submitting = false })
    }
  },

}
</script>
<style lang="scss" scoped>
.feedback-modal {
  svg {
    margin-right: .5rem;
  }
  .submitting {
    color: var(--flat-ui-peter-river);
    text-align: center;
  }
  .submitted {
    color: var(--flat-ui-emerald);
    text-align: center;
  }
}
</style>
