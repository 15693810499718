<template>
  <b-card class="panel" body-class="panel-body" header-bg-variant="dark" header-text-variant="white">
    <template #header>
      <ul v-if="!hideHeader" class="panel-header">
        <li class="title">{{ title }}</li>
        <slot name="icons"></slot>
      </ul>
    </template>
    <slot />
  </b-card>
</template>
<script>

export default {
  props: {
    title: String,
    hideHeader: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.card {
  --panel-border-radius: .25rem;
  --panel-header-bg: #536c79;

  border-radius: var(--panel-border-radius);

  .card-header {
    border-color: rgb(from var(--panel-header-bg) r g b / .95);
    padding: 0.35rem 1.25rem;
    background-color: var(--panel-header-bg);
    color: #fff;
    font-weight: bolder;

    border-radius: var(--panel-border-radius) var(--panel-border-radius) 0 0;

    &:first-child {
      border-radius: calc(var(--panel-border-radius) - 1px) calc(var(--panel-border-radius) - 1px) 0 0;
    }

    .panel-header {
      padding: 0; margin: 0; list-style: none;
      display: flex;
      flex-direction: row;

      .title {
        // I'm not sure why text creeps to top without this top margin
        margin-top: 3px;
        margin-right: 1rem;
      }

      :deep(.panel-header-icon) {
        margin-left: auto;

        ~ .panel-header-icon {
          margin-left: 0.75rem;
        }
      }
    }
  }
}
</style>
