<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <NavSidebar :navItems="nav"/>
      <main class="main">
        <SubscriptionAlertBar/>
        <div class="container-fluid main-pane">
          <router-view v-slot="{ Component }">
            <keep-alive :include="includeInKeepAlive">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </main>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import { useRouterViewMeta } from '@/composables/useRouterViewMeta'
import nav from '../OrgNav'
import Header from './Header.vue'
import NavSidebar from './Sidebar/NavSidebar.vue'
import Footer from './Footer.vue'
import SubscriptionAlertBar from './SubscriptionAlertBar.vue'

export default {
  name: 'Full',
  setup () {
    const routerViewMeta = useRouterViewMeta()
    return {
      ...routerViewMeta
    }
  },
  components: {
    AppHeader: Header,
    AppFooter: Footer,
    NavSidebar,
    SubscriptionAlertBar
  },
  data () {
    return {
      nav: nav.navItems
    }
  }
}
</script>
<style scoped>
.app {
  --base-margin-top: 1.5rem;

  .main-pane {
    margin-top: var(--base-margin-top);
  }
  &.alert-bar-visible {
    .main-pane {
      margin-top: calc(var(--base-margin-top) + var(--alert-bar-xs-height));

      @media screen(sm) {
        margin-top: calc(var(--base-margin-top) + var(--alert-bar-sm-height));
      }
      @media screen(md) {
        margin-top: calc(var(--base-margin-top) + var(--alert-bar-height));
      }
    }
  }
}
</style>
