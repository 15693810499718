import { mapState } from 'vuex'

/**
 * AutoRefresh intelligently handles the following:
 *  1) auto-load on start up
 *  2) auto-refresh on interval
 *  3) go to sleep when idle, or when component deactivated
 *  4) coordinate with manual operations
 */
export default {
  data () {
    return {
      refreshInterval: 5 * 60 * 1000 // 5 minutes
    }
  },
  computed: {
    ...mapState('idle', ['idle'])
  },
  watch: {
    idle (newVal) {
      if (newVal) {
        this.sleepRefresh()
      } else {
        this.wakeRefresh()
      }
    }
  },
  methods: {
    refreshImpl () {
      // component should override this method
      return Promise.resolve()
    },
    // Components that perform manual load/refresh should pass the handler function
    // to coordinateWithRefresh(). For example, a manual refresh should be performed
    // by calling this.coordinateWithRefresh(this.refresh). If an operation is currently
    // in process when this function is called, then it will return a rejected promise.
    coordinateWithRefresh (operation) {
      if (this.refreshOpInProcess) {
        return Promise.reject(new Error('Currently busy with another operation'))
          // Catch to prevent an uncaught promise rejection.
          // It seems to play well now with current usage of AutoRefresh, but I'm
          // not sure if future usage might need to require caller to handle errors.
          .catch(() => {})
      }

      this.cancelRefresh()
      this.refreshOpInProcess = operation()
      return this.refreshOpInProcess
        .catch(() => {})
        .finally(() => { this.finalizeRefreshOp() })
    },
    finalizeRefreshOp () {
      this.refreshOpInProcess = null
      this.lastRefresh = new Date()
      this.scheduleRefresh()
    },
    scheduleRefresh () {
      if (!this.refreshEnabled || this.refreshOpInProcess) {
        return
      }

      this.cancelRefresh()

      const nextRefresh = this.lastRefresh ? Math.max((+this.lastRefresh + this.refreshInterval) - (+new Date()), 0) : 0

      this.refreshTimerId = setTimeout(
        () => {
          this.refreshOpInProcess = this.refreshImpl()
          this.refreshOpInProcess
            .then(result => {
              this.refreshError = false
              return result
            })
            .catch(() => {
              this.refreshError = true
            })
            .finally(() => { this.finalizeRefreshOp() })
        },
        nextRefresh)
    },
    cancelRefresh () {
      if (this.refreshTimerId) {
        clearTimeout(this.refreshTimerId)
        this.refreshTimerId = null
      }
    },
    wakeRefresh () {
      if (!this.refreshBackground) {
        this.enableRefresh()
      }
    },
    sleepRefresh () {
      this.disableRefresh()
    },
    enableRefresh () {
      this.refreshEnabled = true
      this.scheduleRefresh()
    },
    disableRefresh () {
      this.refreshEnabled = false
      this.cancelRefresh()
    }
  },
  mounted () {
    this.refreshBackground = false
    this.enableRefresh()
  },
  beforeUnmount () {
    this.disableRefresh()
  },
  activated () {
    this.refreshBackground = false
    this.enableRefresh()
  },
  deactivated () {
    this.refreshBackground = true
    this.disableRefresh()
  }
}
