<template>
<div class="face-failure-content">
  <div class="left">
    <face-log-photo :faceLogId="faceLogId" />
    <face-status-error errors-only :face-status="faceStatus" style="margin-top: .5rem" />
  </div>
  <div class="right">

    <slot name="employeeQuestion" />

    <b-button-group class="face-decisions">
      <b-button variant="success"
        :disabled="approveDisabled"
        @click="approve"><font-awesome-icon icon="check" class="icon" /> {{ approveText }}</b-button>
      <b-button variant="danger"
        :disabled="rejectDisabled"
        @click="reject"><font-awesome-icon icon="ban" class="icon" /> {{ rejectText }}</b-button>
    </b-button-group>

    <span class="log-detail">
      {{ punchType?.toUpperCase() }}
      @ {{ clockName }}<br/>
      on {{ localDate }}
    </span>

    <multiple-face-matches
      :matches="multipleMatches"
      style="margin-top: .5rem"
    />
  </div>
</div>
</template>
<script>
import moment from 'moment-timezone'
import FaceLogPhoto from '@/views/manage/clocklogs/FaceLogPhoto.vue'
import FaceStatusError from '@/views/settings/employees/FaceStatusError.vue'
import MultipleFaceMatches from '@/views/settings/employees/MultipleFaceMatches.vue'

export default {
  name: 'FaceFailureContent',
  components: {
    FaceLogPhoto,
    FaceStatusError,
    MultipleFaceMatches
  },
  inheritAttrs: false,
  data () {
    return {
      employee: null,
      loading: false,
      loaded: false,
      options: []
    }
  },
  props: {
    date: String,
    timezone: String,
    faceLogId: Number,
    faceStatus: String,
    multipleMatches: Array,
    punchType: String,
    clockName: String,
    approveText: {
      type: String,
      default: 'Approve'
    },
    rejectText: {
      type: String,
      default: 'Reject'
    },
    approveDisabled: {
      type: Boolean,
      default: false
    },
    rejectDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localDate () {
      return moment(this.date).tz(this.timezone).format('llll')
    }
  },
  methods: {
    approve () {
      this.$emit('approve', this.employee)
    },
    reject () {
      this.$emit('reject')
    }
  }
}
</script>
<style scoped>
.face-failure-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  > * {
    margin: 10px;
  }

  .right {
    display: flex;
    flex-direction: column;

    .face-decisions {
      margin-top: 15px;

      .btn {
        margin-right: 10px;
        border-radius: .25rem;

        .icon {
          margin-right: 3px;
        }
      }
    }

    .log-detail {
      margin-top: 10px;
    }
  }
}
</style>
