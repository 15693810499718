<template>
  <!-- The outer v-show enables parent to specify style to be used only when showing. -->
  <div v-show="hasMultipleMatches">
    <div v-if="hasMultipleMatches" class="multiple-matches">
      <div class="header-row">
        <font-awesome-icon icon="triangle-exclamation" aria-hidden="true" />
        Multiple matches
      </div>
      <div class="match-list">
        <div v-for="match in matches" :key="match.userId">
          <b-button variant="link" size="sm" style="padding:0" v-if="canEditWorkers" @click="openUserFormModal(match.userId)">{{ match.userName }}</b-button>
          <template v-else>{{ match.userName }}</template>
          - score {{ match.score }}
          <template v-if="contextUserId && contextUserId === match.userId">*</template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { openUserFormModal } from '@/views/settings/employees/userFormModal'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'MultipleFaceMatches',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  props: {
    matches: Array,
    contextUserId: Number
  },
  computed: {
    ...mapGetters(['canEditWorkers']),
    ...mapState(['organizationId']),
    hasMultipleMatches () {
      return this.matches instanceof Array && this.matches.length > 0
    }
  },
  methods: {
    openUserFormModal (orgUserId) {
      openUserFormModal (orgUserId, this.showModal)
    }
  }
}
</script>
<style lang="scss" scoped>
.multiple-matches {
  border: 1px solid #eaeaea;
  border-radius: .5rem;
  padding: .25rem .5rem;
  background-color: #fcfcfc;

  .header-row {
    color: var(--flat-ui-alizarin);
    svg {
      margin-right: .5rem;
    }
  }
  .match-list {
    margin-top: .25rem;
  }
}
</style>
