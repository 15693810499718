<template>
  <div class="actions">
    <font-awesome-icon :icon="togglingUnread ? 'circle-notch': (unread ? 'envelope': 'envelope-open')" :spin="togglingUnread" :title="`Mark ${unread ? 'Read' : 'Unread'}`" @click="toggleUnread" />
    <font-awesome-icon :icon="deleting ? 'circle-notch': 'xmark'" :spin="deleting" title="Delete" @click="deleteItem" />
  </div>
</template>
<script>
export default {
  name: 'NotificationMessageActionCellRenderer',
  data () {
    return {
      togglingUnread: false,
      deleting: false
    }
  },
  computed: {
    unread () {
      return this.params.data.unread
    },
    processing () {
      return this.togglingUnread || this.deleting
    }
  },
  methods: {
    toggleUnread () {
      if (this.processing) return
      this.togglingUnread = true
      this.params.toggleUnread(this.params).finally(() => this.togglingUnread = false)
    },
    deleteItem () {
      if (this.processing) return
      this.deleting = true
      this.params.deleteItem(this.params).finally(() => this.deleting = false)
    }
  }
}
</script>
<style lang="scss" scoped>
.actions {
  margin-top: .25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  svg {
    cursor: pointer;
    padding: .5rem;
    color: var(--flat-ui-peter-river);
  }
}
</style>
