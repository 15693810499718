import _ from 'lodash'
import moment from 'moment'
import _escapeHtml from 'escape-html'

export function randomDigits (length) {
  let s = Math.floor(Math.random() * Math.pow(10, length))
  s = s.toString().padStart(length, '0')
  return s
}

const RANDOM_TEXT_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export function randomText (length) {
  let text = ''
  for (let i = 0; i < length; i++) {
    text += RANDOM_TEXT_CHARSET.charAt(Math.floor(Math.random() * RANDOM_TEXT_CHARSET.length))
  }
  return text
}

// TODO: Need to handle deeply nested error messages, such as the following:
// TODO: {"payRates":[{"rate":["Ensure that there are no more than 3 decimal places."]},{}]}
export function extractErrorMessage (error) {
  // try to extra a DRF-style response error. we'll just simply read the first error value,
  // even though we could and probably should parse through it for a richer display.
  // TODO: Improve error messaging: (1) richer; (2) form errors perhaps should be inline in form.
  const contentType = _.get(error, 'response.headers.content-type') || ''
  if (contentType.startsWith('application/json')) {
    // _.isPlainObject(error.response.data) && Object.keys(error.response.data).length > 0)
    const errorData = error.response.data
    const errorMessage = extractErrorDataMessage(errorData)
    return errorMessage || error.message
  } else {
    return error.message
  }
}

export function extractErrorDataMessage (errorData) {
  if (_.isArray(errorData)) {
    // Probably was bulk update. Some items may be empty if valid,
    // so choose the first non-empty one that is likely an error.
    errorData = errorData.find(item => !_.isEmpty(item))
  }
  // Check for a detail or message field field.
  const errorDetail = _.isPlainObject(errorData) ? (errorData.detail || errorData.message) : null
  if (errorDetail) {
    errorData = errorDetail
  }

  if (_.isPlainObject(errorData)) {
    if (Object.keys(errorData).length > 0) {
      // Else, choose first error, assuming it's a field.
      const firstError = Object.entries(errorData)[0]
      const fieldName = firstError[0] !== 'nonFieldErrors' ? _.startCase(firstError[0]) : null
      const fieldError = _.isArray(firstError[1]) && firstError[1].length > 0
        ? firstError[1][0] : firstError[1]
      if (_.isPlainObject(fieldError)) return extractErrorDataMessage(fieldError)
      return fieldName ? `${fieldName}: ${fieldError}` : fieldError
    } else {
      return null
    }
  } else {
    return errorData
  }
}

// Model formats are how dates and times are sent on the wire, using moment format.
export const modelDateFormat = 'YYYY-MM-DD'
export const modelTimeFormat = 'HH:mm'
export const modelDateTimeFormat = ''  // ISO format
export const modelDateRegEx = /^\d\d\d\d-\d\d-\d\d$/
export const modelNaiveDateTimeFormat = `${modelDateFormat}T${modelTimeFormat}`

// Moment is somehow able to take this one parse format, and parse all forms of times,
// e.g., '1:00 pm', '01:00 PM', '13:00', etc.
export const parseTimeFormat = 'HH:mm a'

export function parseTimeToModelTime (timeString) {
  if (typeof(timeString) !== 'string') return null
  const m = moment(timeString, parseTimeFormat)
  return m.isValid() ? m.format(modelTimeFormat) : null
}

// Punch notes submitted by time clock get added to punch note string in a particular format.
// We should have originally just made punch notes an object keyed by punch action.
// But we didn't, and so now we'll try to extract that object so we can't associate each note
// with its action.
export function extractPunchNotes (notesString) {
  let notes = {}

  if (!notesString) return notes

  const noteLines = notesString.split('\n')
  let actionCursor

  noteLines.forEach(noteLine => {
    if (!noteLine) return
    if (noteLine === '**') {
      actionCursor = null
      return
    }
    const matchAction = noteLine.match(/\*\* (IN|OUT|TRANSFER) note by employee:/)
    if (matchAction) {
      actionCursor = matchAction[1].toLowerCase()
      return
    }

    const action = actionCursor || 'default'
    notes[action] = notes[action] ? notes[action] + '\n' + noteLine : noteLine
  })

  return notes
}

// Useful for a value that may be single or multiple,
// since isEmpty returns true for numbers.
export function hasValue (value, allowZero = false) {
  if (_.isBoolean(value)) return true
  if (_.isNumber(value)) return !!(allowZero || value)
  return !_.isEmpty(value)
}

export function forceArray (value) {
  return hasValue(value) ? _.castArray(value) : []
}

// Really primitive implementation, because the more comprehensive
// solutions on npm are huge.
export function toPastTense (s) {
  const r = s.toLowerCase()
  return r.endsWith('e') ? s + 'd' : s + 'ed'
}

export function stripSecondsFromTimeString (s) {
  // Strip seconds part off, because the backend can serialize time fields with seconds.
  const matchStrippedSeconds = s ? /^(\d\d:\d\d):\d\d$/.exec(s) : null
  return matchStrippedSeconds ? matchStrippedSeconds[1] : s
}

export function jsonParseSafe(s, fallbackValue) {
  try {
    return JSON.parse(s)
  } catch (e) {
    return fallbackValue
  }
}

export const DefaultShiftColor = '#b7b7b7' // gray

export function* iterateDateRange(startDate, endDate) {
  let cursor = moment(startDate).startOf('day')
  const end = moment(endDate).endOf('day')
  while (cursor.isBefore(end)) {
    yield cursor.format(modelDateFormat)
    cursor = cursor.add(1, 'days')
  }
}

// Customized version of lodash merge that supports arrays:
// https://lodash.com/docs/4.17.15#mergeWith
export function merge(object, ...sources) {
  function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  }

  return _.mergeWith(object, ...sources, customizer)
}

// Our backend limits query branches to this value,
// so we'll need to split queries into chunks.
export const MaxQueryBranches = 100

export function escapeHtml (s) {
  return s ? _escapeHtml(s) : s
}

export function arraysAreUnorderedEqual (array1, array2) {
  return _.isEqual(_.sortBy(array1), _.sortBy(array2))
}

export function finalSet (object, path, value) {
  // This function is different than lodash.set, in that lodash.set calls the setter
  // on each parent property. This approach doesn't work when we want to set a child
  // property of a computed property. So we define this function to only set the deepest
  // property.
  path = path.split('.')
  let parent = object
  for (const [index, key] of path.slice(0, -1).entries()) {
    if (!(key in parent)) throw new Error('All parent objects must exist')
    parent = parent[key]
  }
  parent[_.last(path)] = value
  return object
}

export function formatFileSize (size) {
  // We'll use decimal definition of KB.
  if (size < 1000) return `${size} bytes`
  size = Math.round(size / 1000)
  if (size < 1000) return `${size} KB`
  size = Math.round(size / 1000)
  return `${size} MB`
}

export function getCurrentPayRate (payRates, timezone, refDt) {
  if (_.isEmpty(payRates)) return null
  // Sort in reverse start date order, but put null dates at the end.
  // When sorting use empty string instead of null, because lodash places null values at end,
  // whereas we want null in the beginning.
  const sortedRates = _.reverse(_.sortBy(payRates, payRate => payRate.start || ''))
  const mRef = moment(refDt)
  const currentPayRate = sortedRates.find(payRate =>
    !payRate.start || payRate.start <= mRef.tz(timezone).format(modelDateFormat))
  return currentPayRate
}

// Based on: https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export function isNumeric(v) {
  if (typeof v === 'number') return true
  if (typeof v != "string") return false // we only process strings!
  return !isNaN(v) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(v)) // ...and ensure strings of whitespace fail
}

export const isFireFox = navigator.userAgent.indexOf('Firefox') != -1

